import Cookie from "js-cookie"
const tab = {
    namespaced: true, 
    state: {
        // 隐藏和显示
        isCollapse: false,
      
        tabList: [{
            path: '/Home',
            name: 'home',
            label: '首页',
            icon: 'home'
        }],
        // 高亮显示
        currentMenu: null,
        menu:[]
    },
    mutations: {
        // 隐藏和显示
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse
        },
        
        selectMenu(state, val) {
            if (val.name !== 'home') {
               
                state.currentMenu = val
               
                const result = state.tabList.findIndex(item => item.name === val.name)
                if(result === -1){
                  
                    state.tabList.push(val)
                }else{
                   
                   state.currentMenu = null
                }
            }
        },
        closeTag(state,val){
        
         const result = state.tabList.findIndex(item => item.name === val.name)
          // 它存在的话就删除
         state.tabList.splice(result,1)
        },
        // 获取
        setMenu(state,val){
            state.menu = val
            console.log(state.menu);
            Cookie.set('menu',JSON.stringify(val))
        },
        // 清除
        clearMenu(state,val){
            console.log(val);
            state.tabList = [{
                path: '/Home',
                name: 'home',
                label: '首页',
                icon: 'home'
            }]
            Cookie.remove('menu')
        },
        // 动态添加导航
        addMenu(state){
            if(!Cookie.get('menu')){
                return
            }
            const menu = JSON.parse(Cookie.get('menu'))
            state.menu = menu   
        }
    }
}
export default tab