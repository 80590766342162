import Vue from 'vue'
// ElementUI完整引入
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// ElementUI按需引入
// import { Button,Container,Main,Header,Aside} from 'element-ui';
// 全局样式
import './assets/less/index.less'
import App from './App.vue';

// 引入Vuex文件
import store from './store'

// Vue.use(Button)
// Vue.use(Container)
// Vue.use(Main)
// Vue.use(Header)
// Vue.use(Aside)
Vue.use(ElementUI);

//引用VueRouter
import VueRouter from 'vue-router'
//引入路由器
import router from './router'

import axios from 'axios'
import './api/mock.js'
import 'echarts'
//关闭Vue的生产提示
Vue.config.productionTip = false
//使用插件
Vue.use(VueRouter)

Vue.prototype.$axios = axios

// 全局路由导航守卫
router.beforeEach((to,from,next)=>{
  store.commit('getToken')
  // 获取 token 值
  let token = store.state.user.token
  // token 值不存在 并且当前页不是 login的话 login 的话 继续停留在 login 页面
  if(!token && to.name !== 'login'){
    next({name:'login'})
  }
  else if(token && to.name === 'login') {
    next({name:'home'})
  }
  else{
    // 继续走下去
    next()
  }
})
new Vue({
  store,
  render: h => h(App),
  router,
  beforeCreate(){
    //让所有子组件都能访问和通信的全局组件实例
      Vue.prototype.$bus = this//生命周期中的this本身就是Vue的实例对象
      },
      created(){
      store.commit('tab/addMenu',router)
      }
}).$mount('#app')
