import Mock from 'mockjs'
import home from './mockServeData/home'
import user from './mockServeData/user'
import permission from './mockServeData/permission'

Mock.mock('/home/getData',home.getStatisticalData)

Mock.mock('/user/add','post',user.createUser)

Mock.mock('/user/edit','post',user.updateUser)

Mock.mock('/user/getUser','get',user.getUserList)

Mock.mock('/user/del','post',user.deleteUser)

Mock.mock('/permission/getMenu','post',permission.getMenu)