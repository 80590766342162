//该文件用于创建Vuex中最核心的store
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'
// 侧边栏隐藏和显示文件
import tab from './tab'

import user from './user'
//使用Vuex
Vue.use(Vuex)

//创建并导出store
export default new Vuex.Store({
    modules: {
        // 注册
        tab,
        user
      }
})