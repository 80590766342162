// 该文件专门用于创建于整个应用的路由器
import VueRouter from 'vue-router'
//创建并暴露一个路由器
const router = new VueRouter({
   
    mode:'history',
    routes: [
        {
            // 整个页面
            path: '/',
            name:'Main',
            component:()=> import('../view/MyMain.vue') ,
            redirect:'/home',
            // 子路由
            children:[
               
                {
                    // 首页页面
                    path: '/Home',
                    name:'home',
                    component:()=> import('../view/Home')   
                },
                {
                    // 商品管理页面
                    path: '/User',
                    name:'user',
                    component: ()=> import('../view/User'),
                },
                {
                    // 用户管理页面
                    path: '/Mall',
                    name:'mall',
                    component: ()=> import('../view/Mall'),
                },
                // 其他页面
                {
                   
                    path: '/PageOne',
                    name:'page1',
                    component: ()=> import('../view/other/pageOne.vue'),
                },
                {
                 
                    path: '/PageTwo',
                    name:'page2',
                    component: ()=> import('../view/other/pageTwo.vue'),
                }
            ]
        },
        // 登录页面
        {
            path:'/Login',
            name:'login',
            component:()=> import('../view/Login/login.vue')
        }
    ]
})


export default router